import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import FormInput from '../../../../commonComponents/forms/FormInput';
import OrganizationProp from '../../../../commonComponents/propTypes/organizationProp';
import TenantProp from '../../../../commonComponents/propTypes/tenantProp';
import { BINARY_PLACE_USER_BEHAVIOR } from '../../helpers/binaryPlaceUserBehaviours';
import OrganizationDirectSellingTypesProp from '../../../../commonComponents/propTypes/OrganizationDirectSellingTypesProp';
import { DIRECT_SELLING_RECURRENCE_TYPE } from '../../helpers/directSellingRecurrence';

const recurrenceMonths = Array.from({ length: 12 }, (_, index) => ({
  id: (index + 1).toString(),
  text: moment().month(index).format('MMMM'),
}));

const recurrenceDaysOfMonth = Array.from({ length: 30 }, (_, index) => ({
  id: (index + 1).toString(),
  text: `${index + 1}`,
}));

const recurrenceDaysOfWeek = Array.from({ length: 7 }, (_, index) => ({
  id: (index + 1).toString(),
  text: moment()
    .isoWeekday(index + 1)
    .format('dddd'),
}));

const recurrenceHoursOfDay = Array.from({ length: 24 }, (_, index) => ({
  id: index.toString(),
  text: moment({ hour: index }).format('HH:mm'),
}));

const recurrenceTimezones = moment.tz.names().map(tz => ({
  id: tz,
  text: tz,
}));

const isEmpty = obj =>
  obj == null || (typeof obj === 'object' && Object.keys(obj).length === 0);

const mergeDirectSellingDefaults = (directSelling, directSellingDefault) => {
  const {
    commissionModel,
    recurrence,
    marketTriggers = {},
    schedule = {},
    binaryPlaceUserSchedule,
    binaryPlaceUserBehaviours,
    defaultPlaceUserBehaviour,
  } = directSelling;

  const {
    recurrence: recurrenceDefault,
    marketTriggers: marketTriggersDefault = {},
    schedule: scheduleDefault = {},
    binaryPlaceUserSchedule: binaryPlaceUserScheduleDefault,
    binaryPlaceUserBehaviours: binaryPlaceUserBehavioursDefault,
    defaultPlaceUserBehaviour: defaultPlaceUserBehaviourDefault,
  } = directSellingDefault;
  return {
    commissionModel,
    recurrence: recurrence || recurrenceDefault,
    marketTriggers: !isEmpty(marketTriggers)
      ? marketTriggers
      : marketTriggersDefault,
    schedule: !isEmpty(schedule) ? schedule : scheduleDefault,
    binaryPlaceUserSchedule:
      binaryPlaceUserSchedule || binaryPlaceUserScheduleDefault,
    binaryPlaceUserBehaviours:
      binaryPlaceUserBehaviours || binaryPlaceUserBehavioursDefault,
    defaultPlaceUserBehaviour:
      defaultPlaceUserBehaviour || defaultPlaceUserBehaviourDefault,
  };
};

function TabDirectSelling(props) {
  const {
    t,
    organization,
    tenant,
    isSuperAdmin = false,
    directSellingTypes,
    onChangeDirectSelling = () => {},
  } = props;
  const {
    binaryPlaceUserBehaviours = [],
    defaultPlaceUserBehaviour,
    isManualPlaceUserBehaviourAllowed = false,
    automaticBinaryBehavioursImplement = false,
  } = organization?.directSelling || {};

  const [directSellingSettings, setDirectSellingSettings] = useState(
    mergeDirectSellingDefaults(
      organization?.directSelling || {},
      directSellingTypes?.directSellingDefault || {},
    ),
  );

  const [
    isManualPlaceUserBehaviourAllowedState,
    setIsManualPlaceUserBehaviourAllowed,
  ] = useState(isManualPlaceUserBehaviourAllowed);

  const [binaryBehavioursPlaceUserState, setBinaryBehavioursPlaceUserState] =
    useState(binaryPlaceUserBehaviours || []);
  const [defaultBinaryBehaviourPlacement, setBinaryBehaviourPlacement] =
    useState(defaultPlaceUserBehaviour || BINARY_PLACE_USER_BEHAVIOR.MANUAL);

  if (isSuperAdmin) {
    useEffect(() => {
      onChangeDirectSelling({
        ...directSellingSettings,
        defaultPlaceUserBehaviour: defaultBinaryBehaviourPlacement,
        binaryPlaceUserBehaviours: binaryBehavioursPlaceUserState,
      });
    }, [
      directSellingSettings,
      binaryBehavioursPlaceUserState,
      defaultBinaryBehaviourPlacement,
    ]);
  }

  const items = useMemo(() => {
    const itemsToShow = [
      {
        id: BINARY_PLACE_USER_BEHAVIOR.MANUAL,
        text: t(
          'settings.modules.directSelling.binaryBehavioursPlaceUser.manual',
        ),
      },
    ];
    if (automaticBinaryBehavioursImplement) {
      itemsToShow.push({
        id: BINARY_PLACE_USER_BEHAVIOR.AUTOMATIC_COMMISSION_RULES,
        text: t(
          'settings.modules.directSelling.binaryBehavioursPlaceUser.automaticCommissionRules',
        ),
      });
    }
    itemsToShow.push(
      {
        id: BINARY_PLACE_USER_BEHAVIOR.AUTOMATIC_LEFT_LEG,
        text: t(
          'settings.modules.directSelling.binaryBehavioursPlaceUser.automaticLeftLeg',
        ),
      },
      {
        id: BINARY_PLACE_USER_BEHAVIOR.AUTOMATIC_RIGHT_LEG,
        text: t(
          'settings.modules.directSelling.binaryBehavioursPlaceUser.automaticRightLeg',
        ),
      },
    );
    return itemsToShow;
  }, [t, organization, tenant]);

  const commissionModelTypes = useMemo(() => {
    const types = directSellingTypes.commissionModelTypes.map(it => ({
      id: it,
      text: it
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' '),
    }));
    types.unshift({ id: null, text: 'None' });
    return types;
  }, [t, directSellingTypes]);

  const recurrenceTypes = useMemo(
    () =>
      directSellingTypes.recurrenceTypes.map(r => ({
        id: r,
        text: t(`perform.frequencyMode.${r}`),
      })),
    [t, directSellingTypes],
  );

  const marketTriggersTypes = useMemo(
    () =>
      directSellingTypes.marketTriggers.map(mt => ({
        id: mt,
        text: t(`status.${mt}`),
      })),
    [t, directSellingTypes],
  );

  const itemsForDefault = useMemo(() => {
    if (binaryBehavioursPlaceUserState?.length === 0) {
      return [
        {
          id: BINARY_PLACE_USER_BEHAVIOR.MANUAL,
          text: t(
            'settings.modules.directSelling.binaryBehavioursPlaceUser.manual',
          ),
        },
      ];
    }
    return binaryBehavioursPlaceUserState.map(behaviour => ({
      id: behaviour,
      text: t(
        `settings.modules.directSelling.binaryBehavioursPlaceUser.${behaviour}`,
      ),
    }));
  }, [t, binaryBehavioursPlaceUserState, defaultBinaryBehaviourPlacement]);

  const handleAddOption = useCallback(
    value => {
      if (value === BINARY_PLACE_USER_BEHAVIOR.MANUAL) {
        setIsManualPlaceUserBehaviourAllowed(true);
      }
      setBinaryBehavioursPlaceUserState(prevState => {
        const newBehavioursAvailable = prevState.concat(value);
        if (newBehavioursAvailable.length === 0) {
          setBinaryBehaviourPlacement(BINARY_PLACE_USER_BEHAVIOR.MANUAL);
        }
        if (newBehavioursAvailable?.length === 1) {
          setBinaryBehaviourPlacement(newBehavioursAvailable[0]);
        }
        return newBehavioursAvailable;
      });
    },
    [
      setBinaryBehavioursPlaceUserState,
      setBinaryBehaviourPlacement,
      setIsManualPlaceUserBehaviourAllowed,
    ],
  );

  const handleRemoveOption = useCallback(
    value =>
      setBinaryBehavioursPlaceUserState(prevState => {
        const newBehavioursAvailable = prevState.filter(v => v !== value);
        if (newBehavioursAvailable.length === 0) {
          setBinaryBehaviourPlacement(BINARY_PLACE_USER_BEHAVIOR.MANUAL);
        }
        if (newBehavioursAvailable?.length === 1) {
          setBinaryBehaviourPlacement(newBehavioursAvailable[0]);
        }
        return newBehavioursAvailable;
      }),
    [setBinaryBehavioursPlaceUserState, setBinaryBehaviourPlacement],
  );

  const handleChange = useCallback(
    value => setBinaryBehaviourPlacement(value),
    [setBinaryBehaviourPlacement],
  );
  const handleCheckbox = () => {
    setIsManualPlaceUserBehaviourAllowed(prev => !prev);
  };

  const handleInputChange = useCallback(
    (id, value) => {
      setDirectSellingSettings(prevState => {
        const keys = id.split('.');
        const newState = { ...prevState };
        let currentLevel = newState;
        keys.forEach((key, index) => {
          if (index === keys.length - 1) {
            currentLevel[key] = value;
            if (id === 'commissionModel' && value === 'None') {
              currentLevel[key] = null;
            }
          } else {
            currentLevel[key] = currentLevel[key] || {};
            currentLevel = currentLevel[key];
          }
        });
        return newState;
      });
    },
    [setBinaryBehaviourPlacement],
  );

  return (
    <div>
      <input
        type="hidden"
        id="binaryBehavioursPlaceUserHidden"
        name="binaryBehavioursPlaceUserHidden"
        value={JSON.stringify(binaryBehavioursPlaceUserState)}
      />
      <input
        type="hidden"
        id="defaultBinaryBehaviourPlacement"
        name="defaultBinaryBehaviourPlacement"
        value={defaultBinaryBehaviourPlacement}
      />
      {isSuperAdmin && (
        <div>
          <section>
            <h4>{t('settings.modules.directSelling.commissionModelsTitle')}</h4>
            <FormInput
              id="commissionModel"
              name="commissionModel"
              value={directSellingSettings?.commissionModel}
              onChange={value => handleInputChange('commissionModel', value)}
              label={t('settings.modules.directSelling.commissionModel')}
              labelClassName="col-3 text-right"
              containerClassName="col-5"
              type="select2"
              placeholder={t(
                'settings.modules.directSelling.commissionModel.placeholder',
              )}
              groupProps={{ className: 'row' }}
              inputProps={{
                width: '100%',
                items: commissionModelTypes,
                searchable: true,
              }}
            />
            <FormInput
              id="recurrence"
              name="recurrence"
              value={directSellingSettings?.recurrence}
              onChange={value => handleInputChange('recurrence', value)}
              label={t('settings.modules.directSelling.recurrence')}
              labelClassName="col-3 text-right"
              containerClassName="col-5"
              type="select2"
              placeholder={t(
                'settings.modules.directSelling.recurrence.placeholder',
              )}
              groupProps={{ className: 'row' }}
              inputProps={{
                width: '100%',
                items: recurrenceTypes,
                searchable: false,
              }}
            />
            <br />
            <hr />
          </section>
          <section>
            <h4>{t('settings.modules.directSelling.scheduleTitle')}</h4>
            {directSellingSettings?.recurrence ===
              DIRECT_SELLING_RECURRENCE_TYPE.QUARTERLY && (
              <FormInput
                id="recurrenceStartMonth"
                name="recurrenceStartMonth"
                value={directSellingSettings?.schedule?.recurrenceStartMonth}
                onChange={value =>
                  handleInputChange('schedule.recurrenceStartMonth', value)
                }
                label={t(
                  'settings.modules.directSelling.schedule.recurrenceStartMonth',
                )}
                labelClassName="col-3 text-right"
                containerClassName="col-5"
                type="select2"
                groupProps={{ className: 'row' }}
                inputProps={{
                  width: '100%',
                  items: recurrenceMonths,
                  searchable: true,
                }}
              />
            )}
            {(directSellingSettings?.recurrence ===
              DIRECT_SELLING_RECURRENCE_TYPE.QUARTERLY ||
              directSellingSettings?.recurrence ===
                DIRECT_SELLING_RECURRENCE_TYPE.MONTHLY) && (
              <FormInput
                id="recurrenceStartDayOfMonth"
                name="recurrenceStartDayOfMonth"
                value={
                  directSellingSettings?.schedule?.recurrenceStartDayOfMonth
                }
                onChange={value =>
                  handleInputChange('schedule.recurrenceStartDayOfMonth', value)
                }
                label={t(
                  'settings.modules.directSelling.schedule.recurrenceStartDayOfMonth',
                )}
                labelClassName="col-3 text-right"
                containerClassName="col-5"
                type="select2"
                groupProps={{ className: 'row' }}
                inputProps={{
                  width: '100%',
                  items: recurrenceDaysOfMonth,
                  searchable: true,
                }}
              />
            )}
            {directSellingSettings?.recurrence ===
              DIRECT_SELLING_RECURRENCE_TYPE.WEEKLY && (
              <FormInput
                id="recurrenceStartDayOfWeek"
                name="recurrenceStartDayOfWeek"
                value={
                  directSellingSettings?.schedule?.recurrenceStartDayOfWeek
                }
                onChange={value =>
                  handleInputChange('schedule.recurrenceStartDayOfWeek', value)
                }
                label={t(
                  'settings.modules.directSelling.schedule.recurrenceStartDayOfWeek',
                )}
                labelClassName="col-3 text-right"
                containerClassName="col-5"
                type="select2"
                groupProps={{ className: 'row' }}
                inputProps={{
                  width: '100%',
                  items: recurrenceDaysOfWeek,
                  searchable: false,
                }}
              />
            )}
            <FormInput
              id="recurrenceTriggerStartHour"
              name="recurrenceTriggerStartHour"
              value={
                directSellingSettings?.schedule?.recurrenceTriggerStartHour
              }
              onChange={value =>
                handleInputChange('schedule.recurrenceTriggerStartHour', value)
              }
              label={t(
                'settings.modules.directSelling.schedule.recurrenceTriggerStartHour',
              )}
              labelClassName="col-3 text-right"
              containerClassName="col-5"
              type="select2"
              groupProps={{ className: 'row' }}
              inputProps={{
                width: '100%',
                items: recurrenceHoursOfDay,
                searchable: false,
              }}
            />
            <FormInput
              id="timezone"
              name="timezone"
              value={directSellingSettings?.schedule?.timezone}
              onChange={value => handleInputChange('schedule.timezone', value)}
              label={t('settings.modules.directSelling.schedule.timezone')}
              labelClassName="col-3 text-right"
              containerClassName="col-5"
              type="select2"
              groupProps={{ className: 'row' }}
              inputProps={{
                width: '100%',
                items: recurrenceTimezones,
                searchable: true,
              }}
            />
            <br />
            <hr />
          </section>
          <section>
            <h4>{t('settings.modules.directSelling.marketTriggersTitle')}</h4>
            <FormInput
              id="oldStatus"
              name="marketTriggersoldStatus"
              value={directSellingSettings?.marketTriggers?.oldStatus}
              onChange={value =>
                handleInputChange('marketTriggers.oldStatus', value)
              }
              label={t(
                'settings.modules.directSelling.marketTriggers.oldStatus',
              )}
              labelClassName="col-3 text-right"
              containerClassName="col-5"
              type="select2"
              groupProps={{ className: 'row' }}
              inputProps={{
                width: '100%',
                items: marketTriggersTypes,
                searchable: false,
              }}
            />
            <FormInput
              id="newStatus"
              name="marketTriggersoldNewStatus"
              value={directSellingSettings?.marketTriggers?.newStatus}
              onChange={value =>
                handleInputChange('marketTriggers.newStatus', value)
              }
              label={t(
                'settings.modules.directSelling.marketTriggers.newStatus',
              )}
              labelClassName="col-3 text-right"
              containerClassName="col-5"
              type="select2"
              groupProps={{ className: 'row' }}
              inputProps={{
                width: '100%',
                items: marketTriggersTypes,
                searchable: false,
              }}
            />
            <br />
            <hr />
          </section>
        </div>
      )}
      <section>
        <h4>{t('settings.modules.directSelling.binaryBehavioursTitle')}</h4>
        <FormInput
          id="binaryBehavioursPlaceUser"
          name="binaryBehavioursPlaceUser"
          value={binaryBehavioursPlaceUserState || []}
          onChange={handleAddOption}
          label={t('settings.modules.directSelling.binaryBehavioursPlaceUser')}
          labelClassName="col-3 text-right"
          containerClassName="col-5"
          type="select2"
          placeholder={t(
            'settings.modules.directSelling.binaryBehavioursPlaceUser.placeholder',
          )}
          groupProps={{ className: 'row' }}
          inputProps={{
            onUnselect: handleRemoveOption,
            width: '100%',
            multiple: true,
            allowClear: true,
            items,
          }}
        />
        <FormInput
          key={defaultBinaryBehaviourPlacement}
          id="defaultPlaceUserBehaviour"
          name="defaultPlaceUserBehaviour"
          value={defaultBinaryBehaviourPlacement || []}
          onChange={handleChange}
          label={t(
            'settings.modules.directSelling.binaryBehavioursPlaceUser.default',
          )}
          labelClassName="col-3 text-right"
          containerClassName="col-5"
          type="select2"
          placeholder={t(
            'settings.modules.directSelling.binaryBehavioursPlaceUser.default',
          )}
          groupProps={{ className: 'row' }}
          disabled={binaryBehavioursPlaceUserState?.length === 0}
          inputProps={{
            width: '100%',
            items: itemsForDefault,
            searchable: false,
          }}
        />
        <FormInput
          type="checkbox"
          id="isManualPlaceUserBehaviourAllowedState"
          name="isManualPlaceUserBehaviourAllowedState"
          label={t(
            'settings.modules.directSelling.isManualPlaceUserBehaviourAllowed',
          )}
          hint={t(
            'settings.modules.directSelling.isManualPlaceUserBehaviourAllowed.hint',
          )}
          value={isManualPlaceUserBehaviourAllowedState}
          key={isManualPlaceUserBehaviourAllowedState}
          onChange={handleCheckbox}
          groupProps={{ className: 'row' }}
          inputClassName={`directSellingCheckbox ${
            binaryBehavioursPlaceUserState.includes(
              BINARY_PLACE_USER_BEHAVIOR.MANUAL,
            ) && 'checkbox-disabled'
          }`}
          labelClassName="col-3 text-right label-checkbox"
          containerClassName="col-5"
          disabled={binaryBehavioursPlaceUserState.includes(
            BINARY_PLACE_USER_BEHAVIOR.MANUAL,
          )}
        />
      </section>
    </div>
  );
}

TabDirectSelling.propTypes = {
  t: PropTypes.func.isRequired,
  organization: PropTypes.shape(OrganizationProp.propType).isRequired,
  tenant: PropTypes.shape(TenantProp.propType).isRequired,
  directSellingTypes: PropTypes.shape(
    OrganizationDirectSellingTypesProp.propType,
  ).isRequired,
  isSuperAdmin: PropTypes.bool.isRequired,
  onChangeDirectSelling: PropTypes.func.isRequired,
};

export default TabDirectSelling;
